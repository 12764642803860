import React from 'react'
import logo from '../assets/images/logo-digitalizador.png'

const BannerKitDigital = (props) => (
    <section id="banner" className="style2">
        <div className="inner">
            <header className="major">
	        <h1>Programa Kit Digital</h1>
                <div className="logo"><img src={logo} alt="" width="70%"/></div>
            </header>
            <div className="content">
                <p>Hasta 12.000€ para digitalizar tu empresa</p>
            </div>
        </div>
    </section>
)

export default BannerKitDigital

