import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import BannerKitDigital from '../components/BannerKitDigital'
import Layout from '../components/layout'

import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const About = (props) => (
    <Layout>
        <Helmet
            title="Kit Digital"
            meta={[
                { name: 'description', content: 'Kit Digital Page' },
                { name: 'keywords', content: 'Kit Digital Page' },
            ]}
        >
        </Helmet>

        <BannerKitDigital />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>¿Qué es el Programa Kit Digital?</h2>
                    </header>
	            <p> Una iniciativa del Gobierno de España, dirigida a pequeñas empresas, pymes y autónomos, que tiene como objetivo subvencionar la implantación de soluciones digitales disponibles en el mercado para conseguir un avance significativo en el nivel de madurez digital.</p>
	<p>Puedes acceder <a href="https://acelerapyme.gob.es/sites/acelerapyme/files/2021-11/2021125_Kit%20Digital_Gui%CC%81a%20ra%CC%81pida%20para%20pymes_V14.pdf">aqui</a> a la guía en pdf del Kit Digital para conocer más detalles sobre la subvención</p>
                    <p><b>Para que gracias a la tecnología puedas evolucionar y mejorar tu negocio. </b></p>

                    <header className="major">
                        <h2>¿Qué soluciones ofertamos?</h2>
                    </header>
<p>Las cuantías máximas por servicio en las que se puede emplear el bono digital varían según el segmento de beneficiario y el número de empleados de la empresa. </p>
	<p><b>Nubizzi</b> está en proceso de homologación como Agente Digitalizador. Ofrecemos las siguientes soluciones adaptados al bono digital:</p>
	<div className="table-wrapper">
         <table>
             <thead>
                 <tr>
                     <th>Servicio de digitalización</th>
                     <th>Importe bono</th>
                 </tr>
             </thead>
             <tbody>
                 <tr>
                     <td><b>Gestión de clientes.</b> Digitalizar y optimizar la gestión de las relaciones comerciales con clientes y proveedores.</td>
                     <td>Desde 2.000€ hasta 4.000€</td>
                 </tr>
                 <tr>
                     <td><b>Business Intelligence y Analítica.</b> Explotación de los datos de la empresa para una mejora en el negocio y la toma de decisiones importantes.</td>
                     <td>Desde 1.500€ hasta 4.000€</td>
                 </tr>
                 <tr>
                     <td><b>Gestión de procesos.</b> Digitalizar y automatizar procesos de la empresa relacionados con aspectos productivos y operativos.</td>
                     <td>Desde 500€ hasta 6.000€</td>
                 </tr>
                 <tr>
                     <td><b>Factura electrónica.</b> Digitalizar el flujo de emisión de las facturas a tus clientes.</td>
                     <td>Desde 500€ hasta 1.000€</td>
                 </tr>
                 <tr>
                     <td><b>Comunicaciones seguras.</b> Proporcionar a las empresas seguridad en las conexiones entre los dispositivos de sus empleados y la empresa.</td>
                     <td>125€/usuario, hasta 6.000€</td>
                 </tr>
                 <tr>
                     <td><b>Ciberseguridad.</b> Implantar soluciones que permitan la colaboración más eficiente entre los trabadores de las empresas.</td>
                     <td>125€/usuario, hasta 6.000€</td>
                 </tr>
             </tbody>
         </table>
        </div>
                    <header className="major">
                        <h2>¿Qué pasos tienes que seguir?</h2>
                    </header>
     <ul className="alt">
	<li>1. Regístrate en <a href="https://www.acelerapyme.es/user">www.acelerapyme.es</a> y completa el <a href="https://www.acelerapyme.es/quieres-conocer-el-grado-de-digitalizacion-de-tu-pyme">test de autodiagnóstico</a></li>
	<li>2. Consulta el <a href="https://www.acelerapyme.es/kit-digital/soluciones-digitales">catálogo de soluciones digitales</a>, donde podrás escoger una o
varias de las que ofrecen los agentes digitalizadores. Las que mejor se adapten a
las necesidades de tu negocio.</li>
	<li>3. Solicita la ayuda Kit Digital en la <a href="https://sede.red.gob.es">sede electrónica de Red.es</a>. Completa todos los pasos del formulario.</li>
	<li>4. Accede al catálogo de agentes digitalizadores y decide con quién quieres
desarrollar tu solución digital.</li>
	<li>5. Escoge a <b>Nubizzi</b> como Agente Digitalizador.</li>
     </ul>
                    <header className="major">
                        <h2>¿Cuáles son los requisitos para solicitar la ayuda?</h2>
                    </header>
	<ul>
	<li>Ser una pequeña empresa, microempresa o autónomo. </li>
        <li>Cumplir los límites financieros y efectivos que definen las categorías de empresas.</li>
        <li>Estar en situación de alta y tener la antigüedad mínima que se establece por convocatoria. </li>
        <li>No tener consideración de empresa en crisis.</li>
        <li>Estar al corriente de las obligaciones tributarias y frente a la Seguridad Social</li>
	<li>No estar sujeta a una orden de recuperación pendiente de la Comisión Europea que haya declarado una ayuda ilegal e incompatible con el mercado común.</li>
        <li>No incurrir en ninguna de las prohibiciones previstas en el artículo 13.2 de la Ley 38/2003, de 17 de noviembre, General de Subvenciones.</li>
        <li>No superar el límite de ayudas minimis (de pequeña cuantía).</li>
	</ul>
                    <header className="major">
                        <h2>¿De cuánto son las ayudas?</h2>
                    </header>
	<p>El Gobierno de España ha segmentado las empresas que se pueden beneficiar de las ayudas del kit digital de Acelera Pyme en 3 grupos:</p>
	<div className="table-wrapper">
         <table>
             <thead>
                 <tr>
                     <th>Segmentos de beneficiarios</th>
                     <th>Importe bono digital</th>
                 </tr>
             </thead>
             <tbody>
                 <tr>
                     <td>Segmento I. Pequeñas empresas de entre 10 y menos de 50 empleados</td>
                     <td>12.000€</td>
                 </tr>
                 <tr>
                     <td>Segmento II. Pequeñas empresas o Microempresas de entre 3 y menos de 10 empleados</td>
                     <td>6.000€</td>
                 </tr>
                 <tr>
                     <td>Segmento III. Pequeñas empresas o Microempresas de entre 1 y menos de 3 empleados y personas en situación de autoempleo</td>
                     <td>2.000€</td>
                 </tr>
             </tbody>
         </table>
        </div>
	<br />
                        <h2>Te ayudamos a solicitar tu bono Kit Digital. Contáctanos para más información</h2>
          </div>
            </section>

    </div>
    </Layout>
)

export default About
